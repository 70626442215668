"use client";

import { usePathname } from "next/navigation";
import Link from "next/link";
import { FC } from "react";
import { cn } from "@/lib/utils";

export const HeaderNavigation: FC = () => {
  const pathname = usePathname();
  const isActive = (path: string) => pathname === path;

  return (
    <nav className="flex flex-row gap-4 items-center max-sm:hidden">
      <Link
        href="/trades"
        className={cn(
          "mt-2 pb-1 border-b-2 border-transparent hover:text-[#F01616] hover:border-b-2 hover:border-[#F01616]",
          isActive("/trades") && "font-bold text-[#F01616] border-[#F01616]"
        )}
      >
        Trades
      </Link>
      <Link
        href="/trades/create"
        className={cn(
          "mt-2 pb-1 border-b-2 border-transparent hover:text-[#F01616] hover:border-b-2 hover:border-[#F01616]",
          isActive("/trades/create") && "font-bold text-[#F01616] border-b-2 border-[#F01616]"
        )}
      >
        Create Trade
      </Link>
      <Link
        href="/library"
        className={cn(
          "mt-2 pb-1 border-b-2 border-transparent hover:text-[#F01616] hover:border-b-2 hover:border-[#F01616]",
          isActive("/library") && "font-bold text-[#F01616] border-b-2 border-[#F01616]"
        )}
      >
        Library
      </Link>
    </nav>
  );
};
