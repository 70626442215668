import { cn } from "@/lib/utils";
import { FC } from "react";

type TradeMatcherLogoProps = {
  className?: string;
};

export const TradeMatcherLogo: FC<TradeMatcherLogoProps> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 1620 1620"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
    >
      <circle cx="810" cy="810" r="760" fill="white" stroke="black" strokeWidth="100" />
      <path
        d="M51.6185 761C77.3648 364.571 407.063 51 810 51C1212.94 51 1542.64 364.571 1568.38 761H51.6185Z"
        fill="#F01616"
        stroke="black"
        strokeWidth="100"
      />
      <path
        d="M814.994 560.085L672.819 675.042C649.172 694.104 644.619 728.415 662.538 752.902C681.484 779.002 718.35 784.134 743.759 764.339L889.606 651.142C899.888 643.224 914.575 644.983 922.653 655.247C930.731 665.511 928.822 680.174 918.541 688.239L887.844 711.993L1148.11 951.291H1209.5C1248.42 951.291 1280 919.765 1280 880.909V693.224C1280 654.368 1248.42 622.842 1209.5 622.842H1097.88H1092H1090.97L1085.24 619.177L978.612 550.994C956.141 536.625 929.85 529 903.119 529C871.1 529 839.962 539.997 814.994 560.085ZM848.481 742.491L772.547 801.436C726.281 837.507 659.159 828.123 624.497 780.615C591.891 735.893 600.116 673.429 643.15 638.678L765.35 539.997C748.313 532.812 729.953 529.147 711.3 529.147C683.688 529 656.809 537.211 633.75 552.461L528 622.842H410.5C371.578 622.842 340 654.368 340 693.224V880.909C340 919.765 371.578 951.291 410.5 951.291H569.419L703.663 1073.58C732.45 1099.83 776.953 1097.77 803.244 1069.03C811.322 1060.09 816.756 1049.68 819.547 1038.83L844.516 1061.7C873.156 1087.95 917.806 1086.04 944.097 1057.45C950.706 1050.26 955.553 1041.91 958.638 1033.26C987.131 1052.32 1025.91 1048.36 1049.85 1022.26C1076.14 993.666 1074.23 949.091 1045.59 922.845L848.481 742.491Z"
        fill="black"
        stroke="white"
        strokeWidth="40"
      />
    </svg>
  );
};
